import React from 'react';
import Props from './buttonContainerProps';
import Link from '../../atoms/link/link';
import Image from '../../atoms/image/image';

const ButtonContainer: React.FC<Props> = (props) => {
    return (
        <div className={props.classname}>
            {props.link1 && (
                <Link className={props.linkClassname1} href={props.href1} target={props.target}>
                    {props.image1 && (
                        <Image src={props.image1} alt={props.altText1 || 'Default alt text'} className={props.imgClassName1} />
                    )}
                    {props.link1} {/* Länktext */}
                </Link>
            )}
            {props.link2 && (
                <Link className={props.linkClassname2} href={props.href2} target={props.target}>
                    {props.image2 && (
                        <Image src={props.image2} alt={props.altText2 || 'Default alt text'} className={props.imgClassName2} />
                    )}
                    {props.link2} {/* Länktext */}
                </Link>
            )}
        </div>
    );
}

export default ButtonContainer;