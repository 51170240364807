import { Routes, Route } from "react-router-dom";
import ReactGA from "react-ga4";

// Stilfil
import './App.css';
import Homepage from '../src/views/Homepage';


ReactGA.initialize("G-28K51Z81HV");

ReactGA.send({
  hitType: "pageview",
  page: window.location.pathname,
});

function App() {
  
  return (
    <div className="App">
      <Routes>
        <Route>
          <Route path="/" element={<Homepage />}/>
        </Route>
      </Routes>        
    </div>
  );
  
}

export default App;
