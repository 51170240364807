import React from 'react';
import Props from './linkProps';

const Link: React.FC<Props> = (props) => {
    return (
        <a href={props.href} className={props.className} target={props.target}>
            {props.text}
            {props.children}
        </a>
    );
};

export default Link;