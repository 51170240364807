import React from 'react';
import Props from './imageContainerProps';
import Image from '../../atoms/image/image';

const ImageContainer: React.FC<Props> = (props) => {
    return (
        <picture className={props.classname}>
            {props.sourceSet1 && (
                <source srcSet={props.sourceSet1} type={props.typeSource1} media={props.media1} height={props.height1} width={props.width1} />
            )}
            {props.sourceSet2 && (
                <source srcSet={props.sourceSet2} type={props.typeSource2} media={props.media2} height={props.height2} width={props.width2} />
            )}
            <Image src={props.imageSrc} alt={props.altText} className={props.imageClassName} />
        </picture>
    );
};

export default ImageContainer;
