import React from 'react';
import Props from './labelsProps';

const Label: React.FC<Props> = (props) => {
    return (
        <p className={props.classname}>{props.text}</p>
    );
};

export default Label;
