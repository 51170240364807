import React from 'react';
import Props from './techBoxProps';
import Label from '../../atoms/lables/labels';

const TechBoxContainer: React.FC<Props> = (props) => {
    return (
        <div className={props.classname}>
            {props.labelText1 && <Label text={props.labelText1} classname={props.labelClassname} />}
            {props.labelText2 && <Label text={props.labelText2} classname={props.labelClassname} />}
            {props.labelText3 && <Label text={props.labelText3} classname={props.labelClassname} />}
            {props.labelText4 && <Label text={props.labelText4} classname={props.labelClassname} />}
        </div>
    );
};

export default TechBoxContainer;