import { useRef, useState } from 'react';
// Ikoner
import github from '../assets/icons/github.svg';
import redirect from '../assets/icons/redirect.svg';
import html from '../assets/icons/html5.svg';
import css from '../assets/icons/css3-alt.svg';
import js from '../assets/icons/js.svg';
import php from '../assets/icons/php.svg';
import react from '../assets/icons/react.svg';
import vue from '../assets/icons/vuejs.svg';
import laravel from '../assets/icons/laravel.svg';
import sass from '../assets/icons/sass.svg';
import wordpress from '../assets/icons/wordpress.svg';
import cnet from '../assets/icons/cnet.svg';
import typescript from '../assets/icons/typescript.svg';
import accesability from '../assets/icons/universal-access-solid.svg';
import database from '../assets/icons/database-solid.svg';
import mail from '../assets/icons/mail.svg';
import LightbulbOn from '../assets/icons/lightbulb-regular.svg';
import LightbulbOff from '../assets/icons/lightbulb-solid.svg';
// Bilder 
import blogportalWebp from '../assets/images/blogportal.webp';
import blogportal from '../assets/images/blogportal.png';
import animal from '../assets/images/djurkliniken.jpg';
import animalWebp from '../assets/images/djurkliniken.webp';
import profileImgWebp from '../assets/images/profilbildSquare.webp';
import profileImg from '../assets/images/profilbildSquare.jpg';
import colorPalette from '../assets/images/color-palette.png';
import colorPaletteWebp from '../assets/images/color-palette.webp';
import adviceImg from '../assets/images/advicegen.png';
import adviceImgWebp from '../assets/images/advicegen.webp';
import calculatorImg from '../assets/images/calculator-app.jpg';
import calculatorImgWebp from '../assets/images/calculator-app.webp';
// Mindre bilder (Mobil)
import bloggportalSmall from '../assets/images/bloggportal-150x100.png';
import animalSmall from '../assets/images/djurkliniken-150x100.png';
import colorPaletteSmall from '../assets/images/color-palette-150x100.png';
import adviceSmall from '../assets/images/advicegen-150x100.png';
import calculatorSmall from '../assets/images/calculator-app-150x100.jpg';
// Komponenter
import Button from '../components/atoms/button/button';
import ProjectCard from '../components/organism/ProjectCard/ProjectCard';
import ImageContainer from '../components/molecule/imageContainer/imageContainer';
import TechBoxContainer from '../components/molecule/techBoxContainer/techBoxContainer';
import ButtonContainer from '../components/molecule/buttonContainer/buttonContainer';
import GithubSvg from '../components/atoms/githubSvg/githubsvg';
import LinkedinSvg from '../components/atoms/linkedinsvg/linkedinsvg';

import "react-toggle/style.css";
import Toggle from 'react-toggle';

function Homepage() {
  const contactRef = useRef<HTMLDivElement>(null);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [overlayVisible, setOverlayVisible] = useState(false);

  const handleMouseMove = (event: { pageX: any; pageY: any; }) => {
    const x = event.pageX; // Hela sidans X-koordinat, inklusive scroll
    const y = event.pageY; // Hela sidans Y-koordinat, inklusive scroll
    setMousePosition({ x, y });
  };

  const toggleDarkmode = () => {
    if(overlayVisible === false) {
      setOverlayVisible(true);
    } else if(overlayVisible === true) {
      setOverlayVisible(false);
    }
  };

  return (
    <div>
     
     {overlayVisible && (
      <div
      className="flashlight-container"
      onMouseMove={handleMouseMove}
      style={{
        position: "absolute",
        width: "100%",
        height: "460vh",
        backgroundColor: "rgba(0, 0, 0, 0.8)",
        backgroundImage: `radial-gradient(
          circle at ${mousePosition.x}px ${mousePosition.y}px,
          rgba(255, 255, 255, 0.8) 100px,
          rgba(255, 255, 255, 0.2) 200px,
          rgba(0, 0, 0, 0.8) 300px
        )`, 
        zIndex: 1,
      }}
    ></div>
    
     )
    }
    
      <div className="hero-container">
        <button className='darkmode-button' onClick={toggleDarkmode}>
          {overlayVisible ? <img src={LightbulbOff} alt="Dark mode icon" style={{ width: '16px', height: '13px', fill: 'white'}}/> :  <img src={LightbulbOn} alt="Light mode icon" style={{ width: '16px', height: '13px' }}/>}
        </button>
      </div>

        <div className='info-container'>
        <div className='text-container'>
          <div className='profile-image-container'>
            {/* <img className='profile-image' src="https://via.placeholder.com/300" alt="" /> */}
            <picture>
              <source srcSet={profileImgWebp} type="image/webp"/>
              <img className='profile-image' src={profileImg} alt="Profilbild på mig" />
            </picture>
          </div>
          <div>
            <h1 className='site-title'>Junior Fullstack-utvecklare</h1>
            <p>
              Hej! &#128075;
            </p>
            <p>
              Mitt namn är Maria och är en nybliven webbutvecklare. 
              Utöver webbutveckling har jag också en kärlek för bakning, virkning, läsning och videospel. 
              Dessa intressen hjälper mig att hålla min kreativitet och problemlösningsförmåga skarp, vilket jag tror bidrar positivt till mitt arbete som utvecklare.
              Jag är inte rädd för att ta mig an nya utmaningar och strävar alltid efter att förbättra mitt arbete. 
              Ta till exempel denna portfolio – enligt mig är den långt ifrån färdig och kommer kanske aldrig att bli det eftersom det alltid finns något nytt att lära sig. 
              En av de stora fördelarna med att vara webbutvecklare är att lärandet aldrig tar slut.
            </p>
          </div>
         
          <Button text={'Kontakta mig'} className={'contact-button'} onClick={() => contactRef.current?.scrollIntoView({ behavior: 'smooth' })} />
          <a href="/CV-Maria-Halvarsson.pdf" download="CV-Maria-Halvarsson.pdf" className={'cv-button'}>
             Mitt CV
          </a>
        </div>
      </div>

      <div className='projects-container'>
          <div className='triangle-top'></div>
         
          <h2 className='projects-title'>PROJECTS</h2>
          <div className='project-info-container'>
            
                <ProjectCard classname='project-1'>
                  
                  <ImageContainer 
                    classname='image-container'
                    sourceSet1={blogportalWebp}
                    sourceSet2={blogportal}
                    typeSource1='image/webp'
                    typeSource2='image/jpeg'
                    imageSrc={bloggportalSmall}
                    imageClassName='project-image'
                    media1='(min-width: 426px)'
                    media2='(min-width: 426px)'
                    height1='200'
                    width1='300'
                    height2='200'
                    width2='300'
                  />
                  <div className='project-text'>
                    <h3 className='project-title'>Bloggportal</h3>
                      <p>
                        Detta projekt är en bloggplattform utvecklad som en del av kursen DT093G vid Webbutvecklingsprogrammet. 
                        Plattformen tillåter användare att registrera sig, logga in, skriva och hantera blogginlägg. 
                        Den har skapats för att demonstrera grundläggande webbutvecklingskoncept med PHP och MySQL.
                      </p>
                  <TechBoxContainer classname='tech-box-container' labelClassname='tech-box' labelText1='PHP' labelText2='MySQL'/>
                  
                  <ButtonContainer 
                        classname='btn-container' 
                        target={'_blank'} 
                        imgClassName1='github-icon'
                        imgClassName2='redirect-icon'
                        image1={github} 
                        image2={redirect} 
                        linkClassname2='redirect-btn' 
                        linkClassname1='github-btn' 
                        link1='GitHub' 
                        link2='Demo' 
                        href1='https://github.com/maha404/DT093G-Projekt' 
                        href2='https://bloggportal.mariahalvarsson.se/index.php'
                      />
                  </div>
                </ProjectCard>

              <ProjectCard classname='project-2'>
              <ImageContainer 
                    classname='image-container'
                    sourceSet1={animalWebp}
                    sourceSet2={animal}
                    typeSource1='image/webp'
                    typeSource2='image/jpeg'
                    imageSrc={animalSmall}
                    imageClassName='project-image'
                    media1='(min-width: 426px)'
                    media2='(min-width: 426px)'
                    height1='200'
                    width1='300'
                    height2='200'
                    width2='300'
                  />
                <div className='project-text'>
                <h3 className='project-title'>Djurklinik</h3>
                  <p>
                    Detta projekt är en webbplatform för en fiktiv djurklinik som del av kursen DT191G vid Webbutvecklingsprogrammet. 
                    Webbplatformen tillåter amvändare att se priser och boka tid för en behandling. I detta projekt ingår också ett admingränssnitt samt en databas.
                    Projektet har skapats med ASP.NET, C#, Razor och en MySQL databas.
                  </p> 
                    <TechBoxContainer classname='tech-box-container' labelClassname='tech-box' labelText1='ASP.NET' labelText2='C#' labelText3='RAZOR' labelText4='MySQL'/>
                    <ButtonContainer 
                        classname='btn-container' 
                        target={'_blank'} 
                        imgClassName1='github-icon'
                        image1={github}
                        linkClassname1='github-btn' 
                        link1='GitHub'
                        href1='https://github.com/maha404/projekt-dt191g/tree/main' 
                      />
                  </div>
              </ProjectCard>

              <ProjectCard classname='project-3'>
              <ImageContainer 
                    classname='image-container'
                    sourceSet1={colorPaletteWebp}
                    sourceSet2={colorPalette}
                    typeSource1='image/webp'
                    typeSource2='image/jpeg'
                    imageSrc={colorPaletteSmall}
                    imageClassName='project-image'
                    media1='(min-width: 426px)'
                    media2='(min-width: 426px)'
                    height1='200'
                    width1='300'
                    height2='200'
                    width2='300'
                  />
                <div className='project-text'>
                <h3 className='project-title'>Färgpalett-generator</h3>
                  <p>
                    Ett litet frontend projekt skapat med React, Colormind API och ClipboardJS. 
                    Här kan du klicka på knappen för att skapa en ny förgpalett eller genom att klicka mellanslag på tangentbordet. 
                    Du kan klicka på rutan med färgen för att spara den till urklipp eller spara hela paletten genom att klicka på "C" på tangentbordet.
                  </p>
                    <TechBoxContainer classname='tech-box-container' labelClassname='tech-box' labelText1='REACT' labelText2='COLORMIND API' labelText3='CSS' />
                    <ButtonContainer 
                        classname='btn-container' 
                        target={'_blank'} 
                        imgClassName1='github-icon'
                        imgClassName2='redirect-icon'
                        image1={github} 
                        image2={redirect} 
                        linkClassname2='redirect-btn' 
                        linkClassname1='github-btn' 
                        link1='GitHub' 
                        link2='Demo' 
                        href1='https://github.com/maha404/random-color-palette-generator' 
                        href2='http://palette-generator.mariahalvarsson.se/'
                      />
                  </div>
              </ProjectCard>
              
              <ProjectCard classname='project-4'>
                <ImageContainer 
                      classname='image-container'
                      sourceSet1={adviceImgWebp}
                      sourceSet2={adviceImg}
                      typeSource1='image/webp'
                      typeSource2='image/jpeg'
                      imageSrc={adviceSmall}
                      imageClassName='project-image'
                      media1='(min-width: 426px)'
                      media2='(min-width: 426px)'
                      height1='200'
                      width1='300'
                      height2='200'
                      width2='300'
                />
                  <div className='project-text'>
                  <h3 className='project-title'>Rådgenerator</h3>
                  <p>
                    En rådgenerator där frontend är fokuset. Webbplatsen visar en ruta ned råd som går att slumpa fram genom en knapp. 
                    Projektet är byggt i HTML, CSS, JavaScript tillsammans med Advice slip API där råden hämtas ifrån. Projektidén kommer från Frontend Mentor.
                  </p>
                    <TechBoxContainer classname='tech-box-container' labelClassname='tech-box' labelText1='HTML' labelText2='CSS' labelText3='JavaScript' labelText4='ADVICE SLIP API' />
                    <ButtonContainer 
                        classname='btn-container' 
                        target={'_blank'} 
                        imgClassName1='github-icon'
                        imgClassName2='redirect-icon'
                        image1={github} 
                        image2={redirect} 
                        linkClassname2='redirect-btn' 
                        linkClassname1='github-btn' 
                        link1='GitHub'                       
                        href1='https://github.com/maha404/advice-generator-app' 
                      />
                  </div>
              </ProjectCard>

              <ProjectCard classname='project-5'>
                <ImageContainer 
                      classname='image-container'
                      sourceSet1={calculatorImgWebp}
                      sourceSet2={calculatorImg}
                      typeSource1='image/webp'
                      typeSource2='image/jpeg'
                      imageSrc={calculatorSmall}
                      imageClassName='project-image'
                      media1='(min-width: 426px)'
                      media2='(min-width: 426px)'
                      height1='200'
                      width1='300'
                      height2='200'
                      width2='300'
                />
                  <div className='project-text'>
                  <h3 className='project-title'>Kalkylator</h3>
                  <p>
                    En kalkylator som går att räkna med och visa resultat. Projektet är byggt i HTML, CSS och JavaScript. Det går även att byta tema på kalkylatorn genom att klicka på en toggle knapp, just nu finns två olika teman att välja mellan.
                  </p>
                    <TechBoxContainer classname='tech-box-container' labelClassname='tech-box' labelText1='HTML' labelText2='CSS' labelText3='JavaScript'/>
                    <ButtonContainer 
                        classname='btn-container' 
                        target={'_blank'} 
                        imgClassName1='github-icon'
                        imgClassName2='redirect-icon'
                        image1={github} 
                        image2={redirect} 
                        linkClassname2='redirect-btn' 
                        linkClassname1='github-btn' 
                        link1='GitHub'  
                        link2='Demo'                     
                        href1='https://github.com/maha404/calculator-app-main'
                        href2='http://calculator-app.mariahalvarsson.se/'
                      />
                  </div>
              </ProjectCard>

          </div>
      </div>

      <div className='skills-container'>
        <h2 className='skills-title'>SKILLS</h2>
        <div className='flex-container'>
          {/* <div className="flex-item"> */}

              <div className='skill'>
                  <div>
                    <img className='skill-icon-html' src={html} alt="HTML ikon" />
                    <img className='skill-icon-css' src={css} alt="CSS ikon" />
                  </div>
                  <p>HTML & CSS</p>
              </div>
              <div className='skill'>
                <img className='skill-icon-js' src={js} alt="JavaScript ikon" />
                <p>JAVASCRIPT</p>
              </div>
              <div className='skill'>
                <img className='skill-icon-php' src={php} alt="PHP ikon" />
                <p>PHP</p>
              </div>
          {/* </div> */}

          {/* <div className="flex-item"> */}
            <div className='skill'>
              <img className='skill-icon-react' src={react} alt="React ikon" />
              <p>REACT</p>
            </div>
            <div className='skill'>
              <img className='skill-icon-vue' src={vue} alt="Vue ikon" />
              <p>VUE</p>
            </div>
            <div className='skill'>
              <img className='skill-icon-laravel' src={laravel} alt="Laravel ikon" />
              <p>LARAVEL</p>
            </div>
          {/* </div> */}

          {/* <div className="flex-item"> */}
            <div className='skill'>
              <img className='skill-icon-sass' src={sass} alt="Sass ikon" />
              <p>SASS</p>
            </div>
            <div className='skill'>
              <img className='skill-icon' src={cnet} alt="C#.NET ikon" />
              <p>C#.NET</p>
            </div>
            <div className='skill'>
              <img className='skill-icon' src={wordpress} alt="Wordpress ikon" />
              <p>WORDPRESS</p>
            </div>
          {/* </div> */}

          {/* <div className="flex-item"> */}
            <div className='skill'>
                  <img className='skill-icon-sql' src={database} alt="Databas ikon" />
                  <p>SQL</p>
                </div>
                <div className='skill'>
                  <img className='skill-icon' src={accesability} alt="Tillgänglighet ikon" />
                  <p>TILLGÄNGLIGHET</p>
                </div>
                <div className='skill'>
                  <img className='skill-icon' src={typescript} alt="TypeScript ikon" />
                  <p>TYPESCRIPT</p>
                </div>
          {/* </div> */}

        </div>
        <div className='triangle-bottom'></div>
      </div>
      
      <div ref={contactRef}>
      <footer>
        <h3 className='footer-title'>KONTAKT</h3>
        <div className='mail-container'>
          <img className='mail-icon' src={mail} alt="Mail ikon" /> <a href="mailto: maria_halvarsson@hotmail.se" className='email-link'> maria_halvarsson@hotmail.se </a>
        </div>
        <div className='socialmedia-container'>
          <a href="https://github.com/maha404" target='_blank'><GithubSvg  className='github-icon-1'/></a>
          <a href="https://www.linkedin.com/in/maria-halvarsson-043614191/" target='_blank'><LinkedinSvg className='linkedin-icon'/></a>
        </div>
        <p>© 2024 - All rights reserved.</p>
      </footer>
      </div>
    </div>
    );
}
  

  export default Homepage;