import React from 'react';
import Props from './ProjectCardProps';


import { motion, useScroll } from 'framer-motion';

const ProjectCard: React.FC<Props> = (props) => { 
    return(
        <motion.div className={props.classname}
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1, scale: 1 }}
            animate={{ opacity: 1, scale: 0.5 }}
            transition={{ duration: 0.5 }} 
            viewport={{ once: true }}
        >
            {props.children}
        </motion.div>
    );
};

export default ProjectCard;